import { useDispatch } from 'react-redux'
import actions from 'core/store/actions'

export const useLogOut = () => {
    const dispatch = useDispatch()
    const logOut = () => {
        dispatch(actions.logOut())
    }

    return {
        logOut,
    }
}
