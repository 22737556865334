import { useState } from 'react'
import { mutations } from 'core/graphql/mutations'
import { generateError } from 'core/graphql/error'

export const useForgottenPassword = () => {
    const [forgottenPasswordInProgress, setForgottenPasswordInProgress] = useState(false)
    const [forgottenPasswordUpdateInProgress, setForgottenPasswordUpdateInProgress] = useState(false)

    const forgottenPassword = (username: string): Promise<boolean> => {
        setForgottenPasswordInProgress(true)
        return mutations
            .forgottenPassword(username)
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setForgottenPasswordInProgress(false)
            })
    }

    const forgottenPasswordUpdate = (username: string, code: string, password: string): Promise<boolean> => {
        setForgottenPasswordUpdateInProgress(true)
        return mutations
            .forgottenPasswordUpdate(username, code, password)
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setForgottenPasswordUpdateInProgress(false)
            })
    }

    return {
        forgottenPassword,
        forgottenPasswordUpdate,
        forgottenPasswordInProgress,
        forgottenPasswordUpdateInProgress,
    }
}
