import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import PageLayout from 'components/layouts/pageLayout'
import SignDialog from 'containers/common/signDialog'
import { useLogOut } from 'core/services/user/useLogOut'

interface Props {
    Parent?: React.ComponentType
}

const Main: React.FC<Props> = ({ children, Parent }) => {
    const { init, user } = useSelector(({ app }: ApplicationState) => ({
        init: app.init,
        user: app.user,
    }))

    const { logOut } = useLogOut()

    const [openSign, setOpenSign] = useState<boolean>(false)

    const handleLogOut = () => {
        logOut()
    }

    const handleOpenSign = () => {
        setOpenSign(true)
    }

    const handleCloseSign = () => {
        setOpenSign(false)
    }

    return (
        <PageLayout Parent={Parent} isInit={init} user={user} openSign={handleOpenSign} logOut={handleLogOut}>
            {children}
            <SignDialog open={openSign} onClose={handleCloseSign} />
        </PageLayout>
    )
}

export default Main
