import React from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material'

const Footer: React.FC = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography sx={{ textAlign: 'center', fontSize: '0.8rem', flexGrow: 1 }}>
                    Famiz par Sandrine et Aurélien SEMENCE
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default Footer
