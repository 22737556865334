import { useState } from 'react'
import { mutations } from 'core/graphql/mutations'
import { CheckUserRequest } from 'core/graphql/type'
import { generateError } from 'core/graphql/error'

export const useHasUser = () => {
    const [inProgress, setInProgress] = useState(false)

    const hasUser = (param: CheckUserRequest): Promise<boolean> => {
        setInProgress(true)
        return mutations
            .hasUser(param)
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        hasUser,
        inProgress,
    }
}
