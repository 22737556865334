import React from 'react'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import TextField from 'components/common/forms/textField'
import PasswordField from 'components/common/forms/passwordField'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: SignInFormState) => void
    forgottenPasswordButton: () => React.ReactNode
    children: (
        content: React.ReactNode,
        actions: Array<SubmitButtonProps<React.ElementType>>,
    ) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: SignInFormState
}

export interface SignInFormState {
    username: string
    password: string
}

interface SignInFormError {
    username?: string
    password?: string
}

const SignInForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    forgottenPasswordButton,
}) => {
    const { t } = useTranslation()

    const validate = (values: SignInFormState) => {
        const errors: SignInFormError = {}

        if (!values.username) {
            errors.username = t('formValidation.username')
        }

        if (!values.password) {
            errors.password = t('formValidation.password')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div>
                            <TextField name="username" label={t('form.username')} required disabled />
                            <PasswordField
                                label={t('form.password')}
                                name="password"
                                autoComplete="password"
                                required
                            />
                            <div>{forgottenPasswordButton()}</div>
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default SignInForm
