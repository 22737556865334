import APIError from './api'
import { ErrorResponse } from '@apollo/client/link/error'

export const generateError = (error: ErrorResponse) => {
    if (error === undefined) {
        return new APIError(error)
    }
    const response = error.networkError
    if (error.networkError) {
        const errorType = response?.name
        const message = response?.message
        return new APIError(error, errorType, message)
    } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const errorType = error.graphQLErrors[0].name
        const message = error.graphQLErrors[0].message
        return new APIError(error, errorType, message)
    }
    return new APIError(error)
}
