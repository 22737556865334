import { useState } from 'react'
import { LoginUserRequest, TokenResponse } from 'core/graphql/type'
import { mutations } from 'core/graphql/mutations'
import { generateError } from 'core/graphql/error'
import { useDispatch } from 'react-redux'
import actions from 'core/store/actions'

export const useLoginUser = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const loginUser = (param: LoginUserRequest): Promise<TokenResponse> => {
        setInProgress(true)
        return mutations
            .loginUser(param)
            .then((response) => {
                dispatch(actions.setAuthentication(response))
                return response
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        loginUser,
        inProgress,
    }
}
