import React, { useState } from 'react'
import TextField, { TextFieldProps } from './textField'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const PasswordField: React.FC<TextFieldProps> = ({ autoComplete = 'password', InputProps, ...other }) => {
    const [shouldShowPassword, setShouldShowPassword] = useState(false)

    const handleDownPassword = () => {
        setShouldShowPassword(false)
    }

    const handleShowPassword = () => {
        setShouldShowPassword(true)
    }

    return (
        <TextField
            autoComplete={autoComplete}
            type={shouldShowPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleDownPassword}
                        >
                            {shouldShowPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
                ...InputProps,
            }}
            {...other}
        />
    )
}

export default PasswordField
