import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useSnackbar } from 'notistack'
import APIError from 'core/graphql/error/api'

export enum PageType {
    SIGN_IN,
}

export const useApiErrors = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const displayError = (error: APIError, _?: PageType) => {
        console.error(error, JSON.stringify(error))
        switch (error.message) {
            case 'WRONG_PASSWORD': {
                enqueueSnackbar(t('error.wrongPassword'), {
                    variant: 'error',
                })
                break
            }
            default: {
                enqueueSnackbar(t('error.unexpected'), {
                    variant: 'error',
                })
            }
        }
    }

    return {
        displayError,
    }
}
