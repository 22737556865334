import { TextField as DefaultTextField, TextFieldProps as DefaultTextFieldProps } from '@mui/material'
import React from 'react'
import { Field } from 'react-final-form'

export type TextFieldProps = {
    name: string
} & DefaultTextFieldProps

const TextField: React.FC<TextFieldProps> = ({
    name,
    label,
    onChange: onChangeParent,
    placeholder,
    multiline,
    autoComplete,
    required,
    type,
    helperText,
    className,
    InputProps,
    margin = 'normal',
    variant,
    disabled,
    InputLabelProps,
}) => {
    return (
        <Field name={name}>
            {({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => {
                const showError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <DefaultTextField
                        className={className}
                        label={label}
                        name={name}
                        value={value}
                        variant={variant}
                        margin={margin}
                        disabled={disabled}
                        fullWidth
                        placeholder={placeholder}
                        onChange={(event) => {
                            if (onChangeParent) {
                                onChangeParent(event)
                            }
                            onChange(event)
                        }}
                        helperText={showError ? meta.error || meta.submitError : helperText ?? ' '}
                        error={showError}
                        inputProps={{
                            ...restInput,
                        }}
                        multiline={multiline}
                        autoComplete={autoComplete}
                        required={required}
                        type={type}
                        InputProps={InputProps}
                        InputLabelProps={InputLabelProps}
                        {...rest}
                    />
                )
            }}
        </Field>
    )
}

export default TextField
