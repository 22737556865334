import React from 'react'
import {
    Avatar,
    Button,
    Divider,
    IconButton,
    ListItemIcon,
    ListSubheader,
    Menu,
    MenuItem,
    Skeleton,
} from '@mui/material'
import { UserModel } from 'core/models/userModel'
import { Trans } from 'gatsby-plugin-react-i18next'
import { Logout } from '@mui/icons-material'
import { Link } from '@reach/router'

export interface UserProps {
    isInit: boolean
    user?: UserModel
    openSign: () => void
    logOut: () => void
}

const User: React.FC<UserProps> = ({ isInit, user, openSign, logOut }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    if (!isInit) {
        return (
            <Skeleton variant="circular">
                <Avatar />
            </Skeleton>
        )
    } else {
        const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
        }
        const handleCloseMenu = () => {
            setAnchorEl(null)
        }
        if (user) {
            return (
                <>
                    <IconButton onClick={handleOpenMenu} size="small" sx={{ ml: 2 }}>
                        <Avatar variant="circular">
                            {user.givenName.substring(0, 1).toUpperCase()}
                            {user.familyName.substring(0, 1).toUpperCase()}
                        </Avatar>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                'overflow': 'visible',
                                'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                'mt': 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 20,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <ListSubheader>
                            <Trans i18nKey="menu.account" values={{ ...user }} />
                        </ListSubheader>
                        <MenuItem component={Link} to={'/mon-compte.html'}>
                            <Trans i18nKey="menu.updateAccount" />
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                logOut()
                                handleCloseMenu()
                            }}
                        >
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            <Trans i18nKey="actions.signOut" />
                        </MenuItem>
                    </Menu>
                </>
            )
        } else {
            return (
                <Button variant="contained" color="secondary" onClick={openSign}>
                    <Trans i18nKey="actions.sign" />
                </Button>
            )
        }
    }
}

export default User
