import React from 'react'
import {
    FormHelperText,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material'
import { Trans } from 'react-i18next'
import DoneIcon from '@mui/icons-material/Done'
import WarningIcon from '@mui/icons-material/Warning'

interface ItemProps {
    text: React.ReactNode
    valid: boolean
}

const Item: React.FC<ItemProps> = ({ text, valid }) => {
    const theme = useTheme()
    return (
        <ListItem disableGutters sx={{ padding: 0.25 }}>
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    paddingRight: 2,
                }}
            >
                {valid ? (
                    <DoneIcon htmlColor={theme.palette.success.main} fontSize="small" />
                ) : (
                    <WarningIcon htmlColor={theme.palette.warning.main} fontSize="small" />
                )}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ fontSize: 'inherit' }} />
        </ListItem>
    )
}

interface Props {
    value: string | undefined
}

const lowercaseRegex = new RegExp('[a-z]')
const uppercaseRegex = new RegExp('[A-Z]')
const numberRegex = new RegExp('[0-9]')
const lengthRegex = new RegExp('.{8,}')
const specialRegex = new RegExp('\\W')
const PasswordRules: React.FC<Props> = ({ value }) => {
    const hasLowercase = () => {
        return lowercaseRegex.test(value ?? '')
    }
    const hasUppercase = () => {
        return uppercaseRegex.test(value ?? '')
    }
    const hasNumber = () => {
        return numberRegex.test(value ?? '')
    }
    const hasRequiredLength = () => {
        return lengthRegex.test(value ?? '')
    }
    const hasSpecial = () => {
        return specialRegex.test(value ?? '')
    }

    return (
        <FormHelperText component="div">
            <Typography sx={{ fontSize: 'inherit' }}>
                <Trans i18nKey="form.passwordRules.title" />
            </Typography>
            <List dense disablePadding>
                <Item text={<Trans i18nKey="form.passwordRules.lowercase" />} valid={hasLowercase()} />
                <Item text={<Trans i18nKey="form.passwordRules.uppercase" />} valid={hasUppercase()} />
                <Item text={<Trans i18nKey="form.passwordRules.number" />} valid={hasNumber()} />
                <Item text={<Trans i18nKey="form.passwordRules.length" />} valid={hasRequiredLength()} />
                <Item text={<Trans i18nKey="form.passwordRules.special" />} valid={hasSpecial()} />
            </List>
        </FormHelperText>
    )
}

export default PasswordRules
