import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import CodeField from 'components/common/forms/codeField'
import PasswordField from 'components/common/forms/passwordField'
import PasswordRules from 'components/common/passwordRules'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: ForgotPasswordUpdateFormState) => void
    children: (
        content: React.ReactNode,
        actions: Array<SubmitButtonProps<React.ElementType>>,
    ) => React.ReactNode
    inProgress: boolean
    initialValues: ForgotPasswordUpdateFormState
}

export interface ForgotPasswordUpdateFormState {
    code: string
    password: string
    passwordConfirmation: string
}

interface ForgotPasswordUpdateFormErrors {
    code?: string
    password?: string
    passwordConfirmation?: string
}

const ForgotPasswordUpdateForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    inProgress,
    initialValues,
}) => {
    const { t } = useTranslation()

    const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})')
    const validate = (values: ForgotPasswordUpdateFormState) => {
        const errors: ForgotPasswordUpdateFormErrors = {}

        if (!values.code) {
            errors.code = t('formValidation.code')
        } else if (values.code.length !== 6) {
            errors.code = t('formValidation.codeLength')
        }

        if (!values.password) {
            errors.password = t('formValidation.password')
        } else if (!strongPasswordRegex.test(values.password)) {
            errors.password = t('formValidation.passwordStrength')
        }

        if (!values.passwordConfirmation) {
            errors.passwordConfirmation = t('formValidation.passwordConfirmation')
        } else if (values.password !== values.passwordConfirmation) {
            errors.passwordConfirmation = t('formValidation.passwordConfirmationNotMatch')
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    {children(
                        <>
                            <CodeField name="code" />
                            <PasswordField
                                name="password"
                                label={t('form.password')}
                                autoComplete="new-password"
                            />
                            <FormSpy subscription={{ values: true }}>
                                {(props) => <PasswordRules value={props.values.password} />}
                            </FormSpy>
                            <PasswordField
                                name="passwordConfirmation"
                                label={t('form.passwordConfirmation')}
                                autoComplete="new-password"
                            />
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default ForgotPasswordUpdateForm
