import React from 'react'
import { AppBar, Box, Toolbar } from '@mui/material'
import User, { UserProps } from './user'

interface Props extends UserProps {}

const Header: React.FC<Props> = ({ ...userProps }) => {
    return (
        <AppBar position="sticky" color="transparent">
            <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <User {...userProps} />
            </Toolbar>
        </AppBar>
    )
}

export default Header
