import React from 'react'
import Header from './components/header'
import Footer from './components/footer'
import { UserProps } from './components/user'
import { Box, styled } from '@mui/material'
import waves from 'assets/main/waves.svg'

const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    backgroundImage: 'url(' + waves + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
}))

interface Props extends UserProps {
    Parent?: React.ComponentType
}

const PageLayout: React.FC<Props> = ({ children, Parent = (props) => <main {...props} />, ...userProps }) => {
    return (
        <Parent>
            <Root>
                <Header {...userProps} />
                {children}
                <Footer />
            </Root>
        </Parent>
    )
}

export default PageLayout
