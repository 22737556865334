import { useState } from 'react'
import { mutations } from 'core/graphql/mutations'
import { CreateUserRequest } from 'core/graphql/type'
import { generateError } from 'core/graphql/error'
import { UserModel } from 'core/models/userModel'

export const useCreateUser = () => {
    const [inProgress, setInProgress] = useState(false)

    const createUser = (param: CreateUserRequest): Promise<UserModel> => {
        setInProgress(true)
        return mutations
            .createUser(param)
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        createUser,
        inProgress,
    }
}
