import { ErrorResponse } from '@apollo/client/link/error'

export const UnexpectedError = 'UnexpectedError'

export class APIError extends Error {
    type: string
    data: any
    error: ErrorResponse

    constructor(error: ErrorResponse, type: string = UnexpectedError, message?: string, data?: any) {
        super(message ? message : type)
        this.name = type
        this.type = type
        this.data = data
        this.error = error
    }
}

export default APIError
