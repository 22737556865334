import { Dialog, styled } from '@mui/material'

const TopRightDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paperScrollBody': {
        verticalAlign: 'top',
        float: 'right',
        marginTop: 11,
        marginRight: 24,
    },
})) as typeof Dialog

export default TopRightDialog
