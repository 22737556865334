import React, { useState } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material'
import CloseButton from 'components/common/dialogs/closeButton'
import { TransitionProps } from '@mui/material/transitions'
import TopRightDialog from 'components/common/dialogs/topRightDialog'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import SubmitButton from 'components/common/submitButton'
import SignForm, { SignFormState } from 'components/forms/auth/signForm'
import { useHasUser } from 'core/services/user/useHasUser'
import { useCreateUser } from 'core/services/user/useCreateUser'
import { useLoginUser } from 'core/services/user/useLoginUser'
import { useApiErrors } from 'core/services/api/useApiError'
import SignUpForm, { SignUpFormState } from 'components/forms/auth/signUpForm'
import SignInForm, { SignInFormState } from 'components/forms/auth/signInForm'
import { useSnackbar } from 'notistack'
import { useForgottenPassword } from '../../core/services/user/useForgottenPassword'
import ForgotPasswordUpdateForm, {
    ForgotPasswordUpdateFormState,
} from '../../components/forms/auth/forgotPasswordUpdateForm'

export enum Type {
    SIGN,
    SIGN_IN,
    SIGN_UP,
    FORGOTTEN_PASSWORD,
}

interface Props {
    open: boolean
    onClose: () => void
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />
})

const SignDialog: React.FC<Props> = ({ open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()
    const { hasUser, inProgress: checkUserInProgress } = useHasUser()
    const { createUser, inProgress: createUserInProgress } = useCreateUser()
    const { loginUser, inProgress: loginUserInProgress } = useLoginUser()
    const {
        forgottenPassword,
        forgottenPasswordUpdate,
        forgottenPasswordInProgress,
        forgottenPasswordUpdateInProgress,
    } = useForgottenPassword()

    const [type, setType] = useState<Type>(Type.SIGN)
    const [username, setUsername] = useState<string>()

    const handleSign = (values: SignFormState) => {
        return hasUser(values)
            .then((hasAccount) => {
                // handleCloseSign()
                setUsername(values.username)
                if (hasAccount) {
                    setType(Type.SIGN_IN)
                } else {
                    setType(Type.SIGN_UP)
                }
            })
            .catch(displayError)
    }

    const handleSignUp = (values: SignUpFormState) => {
        return createUser(values)
            .then(() => {
                enqueueSnackbar(t('dialogs.signUp.success'))
                return handleSignIn({
                    username: values.username,
                    password: values.password,
                }).then(() => {
                    onClose()
                })
            })
            .catch(displayError)
    }

    const handleSignIn = (values: SignInFormState) => {
        return loginUser(values)
            .then(() => {
                enqueueSnackbar(t('dialogs.signIn.success'))
                onClose()
            })
            .catch(displayError)
    }

    const handleForgotPassword = (username: string) => {
        forgottenPassword(username.trim())
            .then(() => {
                setType(Type.FORGOTTEN_PASSWORD)
            })
            .catch((error) => displayError(error))
    }

    const handleForgotPasswordUpdate = (values: ForgotPasswordUpdateFormState) => {
        forgottenPasswordUpdate(username ?? '', values.code, values.password)
            .then((isValid) => {
                if (isValid) {
                    enqueueSnackbar(t('dialogs.forgottenPassword.success'), {
                        variant: 'success',
                    })
                    setType(Type.SIGN_IN)
                } else {
                    enqueueSnackbar(t('dialogs.forgottenPassword.failed'), {
                        variant: 'error',
                    })
                }
            })
            .catch((error) => displayError(error))
    }

    let title
    let children
    switch (type) {
        case Type.SIGN:
            title = <Trans i18nKey="dialogs.sign.title" />
            children = (
                <SignForm
                    initialValues={{
                        username: username ?? '',
                    }}
                    onSubmit={handleSign}
                    inProgress={checkUserInProgress}
                >
                    {(content, actions) => (
                        <>
                            <DialogContent>
                                <Typography sx={{ marginBottom: 2 }}>
                                    <Trans i18nKey="dialogs.sign.message" />
                                </Typography>
                                {content}
                            </DialogContent>
                            <DialogActions>
                                {actions.map((action, index) => (
                                    <SubmitButton
                                        key={index}
                                        {...action}
                                        variant="contained"
                                        color="secondary"
                                        sx={{ width: 200 }}
                                    />
                                ))}
                            </DialogActions>
                        </>
                    )}
                </SignForm>
            )
            break
        case Type.SIGN_IN:
            title = <Trans i18nKey="dialogs.signIn.title" />
            children = (
                <SignInForm
                    initialValues={{
                        username: username ?? '',
                        password: '',
                    }}
                    onSubmit={handleSignIn}
                    inProgress={loginUserInProgress}
                    forgottenPasswordButton={() => (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <SubmitButton
                                variant="contained"
                                loading={forgottenPasswordInProgress}
                                onClick={() => handleForgotPassword(username ?? '')}
                                disabled={loginUserInProgress}
                            >
                                <Trans i18nKey="actions.forgottenPassword" />
                            </SubmitButton>
                        </Box>
                    )}
                >
                    {(content, actions) => (
                        <>
                            <DialogContent>
                                <Typography sx={{ marginBottom: 2 }}>
                                    <Trans i18nKey="dialogs.signIn.message" />
                                </Typography>
                                {content}
                            </DialogContent>
                            <DialogActions>
                                <SubmitButton variant="contained" onClick={() => setType(Type.SIGN)}>
                                    <Trans i18nKey="actions.previous" />
                                </SubmitButton>
                                {actions.map((action, index) => (
                                    <SubmitButton
                                        key={index}
                                        {...action}
                                        variant="contained"
                                        color="secondary"
                                        sx={{ width: 200 }}
                                    />
                                ))}
                            </DialogActions>
                        </>
                    )}
                </SignInForm>
            )
            break
        case Type.SIGN_UP:
            title = <Trans i18nKey="dialogs.signUp.title" />
            children = (
                <SignUpForm
                    initialValues={{
                        username: username ?? '',
                        email: '',
                        givenName: '',
                        familyName: '',
                        password: '',
                        passwordConfirmation: '',
                    }}
                    onSubmit={handleSignUp}
                    inProgress={createUserInProgress || loginUserInProgress}
                >
                    {(content, actions) => (
                        <>
                            <DialogContent>
                                <Typography sx={{ marginBottom: 2 }}>
                                    <Trans i18nKey="dialogs.signUp.message" />
                                </Typography>
                                {content}
                            </DialogContent>
                            <DialogActions>
                                <SubmitButton variant="contained" onClick={() => setType(Type.SIGN)}>
                                    <Trans i18nKey="actions.previous" />
                                </SubmitButton>
                                {actions.map((action, index) => (
                                    <SubmitButton
                                        key={index}
                                        {...action}
                                        variant="contained"
                                        color="secondary"
                                        sx={{ width: 200 }}
                                    />
                                ))}
                            </DialogActions>
                        </>
                    )}
                </SignUpForm>
            )
            break
        case Type.FORGOTTEN_PASSWORD:
            title = <Trans i18nKey="dialogs.forgottenPassword.title" />
            children = (
                <ForgotPasswordUpdateForm
                    initialValues={{
                        code: '',
                        password: '',
                        passwordConfirmation: '',
                    }}
                    onSubmit={handleForgotPasswordUpdate}
                    inProgress={forgottenPasswordUpdateInProgress}
                >
                    {(content, actions) => (
                        <>
                            <DialogContent>
                                <Typography sx={{ marginBottom: 2 }}>
                                    <Trans i18nKey="dialogs.forgottenPassword.message" />
                                </Typography>
                                {content}
                            </DialogContent>
                            <DialogActions>
                                <SubmitButton variant="contained" onClick={() => setType(Type.SIGN_IN)}>
                                    <Trans i18nKey="actions.previous" />
                                </SubmitButton>
                                {actions.map((action, index) => (
                                    <SubmitButton
                                        key={index}
                                        {...action}
                                        variant="contained"
                                        color="secondary"
                                        sx={{ width: 200 }}
                                    />
                                ))}
                            </DialogActions>
                        </>
                    )}
                </ForgotPasswordUpdateForm>
            )
    }
    return (
        <TopRightDialog open={open} fullWidth maxWidth="sm" TransitionComponent={Transition}>
            <CloseButton onClose={onClose} />
            <DialogTitle>{title}</DialogTitle>
            {children}
        </TopRightDialog>
    )
}

export default SignDialog
