import React from 'react'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import TextField from 'components/common/forms/textField'
import { Box } from '@mui/material'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: SignFormState) => void
    children: (
        content: React.ReactNode,
        actions: Array<SubmitButtonProps<React.ElementType>>,
    ) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: SignFormState
}

export interface SignFormState {
    username: string
}

interface SignFormError {
    username?: string
}

const SignForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
}) => {
    const { t } = useTranslation()

    const validate = (values: SignFormState) => {
        const errors: SignFormError = {}

        if (!values.username) {
            errors.username = t('formValidation.username')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div>
                            <TextField
                                variant="standard"
                                name="username"
                                label={t('form.username')}
                                required
                            />
                            <Box sx={{ display: 'none' }}>
                                <TextField
                                    label={t('form.password')}
                                    name="password"
                                    autoComplete="password"
                                    type="hidden"
                                />
                            </Box>
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default SignForm
