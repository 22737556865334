import React from 'react'
import { Field } from 'react-final-form'
import ReactCodeInput from 'react-code-input'
import { Box, FormControl, FormHelperText, styled, useTheme } from '@mui/material'

const CodeInput = styled(ReactCodeInput)(({ theme }) => ({
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'maxWidth': 400,
    'margin': theme.spacing(2, 0),
    '& input': {
        'cursor': 'text',
        'width': '12%',
        'margin': theme.spacing(0, 1),
        'fontSize': '1.3rem',
        'display': 'inline-flex',
        'alignItems': 'center',
        'fontFamily': theme.typography.fontFamily,
        'lineHeight': '1.1875em',
        'padding': '18.5px 14px',
        'borderRadius': theme.spacing(0.5),
        'borderColor': 'rgba(0, 0, 0, 0.23)',
        'borderStyle': 'solid',
        'borderWidth': 1,
        'textAlign': 'center',
        'MozAppearance': 'textfield',
        'transition':
            'padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        '@media screen and (max-width: 400px)': {
            padding: '14px 8px',
            margin: theme.spacing(0, 0.5),
            width: '13%',
        },
        '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
    '& input:focus, & input:focus:hover': {
        outline: 0,
        borderColor: theme.palette.primary.main,
        boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
    },
    '& input:hover': {
        borderColor: theme.palette.text.primary,
    },
    '@media screen and (max-width: 400px)': {
        margin: theme.spacing(1, 0),
    },
})) as unknown as typeof ReactCodeInput

interface Props {
    name: string
    margin?: 'dense' | 'normal' | 'none'
}

const CodeField: React.FC<Props> = ({ name, margin = 'normal' }) => {
    const theme = useTheme()
    return (
        <Field name={name}>
            {({ input: { name, onChange }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <FormControl component="fieldset" fullWidth margin={margin} error={hasError}>
                        <Box
                            sx={{
                                'display': 'flex',
                                'alignItems': 'center',
                                'justifyContent': 'center',
                                'flexDirection': 'column',
                                '& > p': {
                                    width: '100%',
                                    maxWidth: 400,
                                },
                            }}
                        >
                            <CodeInput
                                name={name}
                                type="number"
                                inputMode="numeric"
                                fields={6}
                                onChange={(value: string) => onChange(value)}
                                inputStyleInvalid={{
                                    color: theme.palette.error.main,
                                    borderColor: theme.palette.error.main,
                                }}
                                isValid={!hasError}
                            />
                            {meta.error && meta.touched && (
                                <FormHelperText error={hasError}>{meta.error}</FormHelperText>
                            )}
                        </Box>
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default CodeField
